/* eslint-disable */
// import * as WalletConnectProvider from '@walletconnect/web3-provider';

export default function useWalletconnect() {
  const getProvider = () => {
    /*
    const provider = new WalletConnectProvider.WalletConnectProvider({
      infuraId: config.infuraId,
    });
    provider.connector.on('display_uri', (err, payload) => {
      const uri = payload.params[0];
      console.log('WALLETCONNECT QRCODE', uri);
    });
    */
  };
  const connectWallet = async (provider) => {
  };
  const getError = (error) => 'errors.unknown';
  return { getProvider, getError, connectWallet };
};
