import { useStore } from '@/store';
import { getAddress } from '@ethersproject/address';
import { formatUnits } from '@ethersproject/units';
import { shallowEqual } from 'fast-equals';
import localforage from 'localforage';
import Railgun from 'railgun-privacy.js';
import {
  bigInt2ETHAddress, isETHAddress, isValidRailAddress
} from 'railgun-privacy.js/utils';
import { computed, ref } from 'vue';
import useTokenList from './useTokenList';
import useTransactions from './useTransactionHistory';

const { lookupToken } = useTokenList();
const store = useStore();
/* eslint-disable no-param-reassign */

/** @type {Railgun} Railgun object */
let railgun = null;

// fee is added on top of deposits and subtracted from withdrawals
const RP_FEE = 0.0025;

// railguns state is imported or fully synchronized
const loaded = ref(false);
// true while synchronizing
const synchronizing = ref(false);
const smallArtifactsSet = ref(false);
// railgun is synchronized and has small artifacts
const ready = computed(() => (loaded.value && smallArtifactsSet.value));

// railgun address.
const address = ref(null);
// reactive view of user balances in rail
const railUserTokens = ref([]);
const balances = ref([]); // track balances. watch and update railUserTokens on change.

export default function useRailgun() {
  function getStorageKey() {
    const addr = store.address.value;
    const network = store.chainId.value;
    return `rp_${network}_${addr}`;
  }

  const sync = async () => railgun.sync();
  const txHistory = useTransactions();

  const balance = () => railgun.balance.balance();

  const exportState = async () => {
    await localforage.setItem(getStorageKey(), await railgun.exportState());
  };

  const balancesChanged = (update, old) => (
    old.length !== update.length
    || !update.every(newBal => old.find(oldBal => shallowEqual(oldBal, newBal)))
  );
  // update railUserTokens reactive view when railgun balance updates
  const onBalanceUpdate = async (balanceUpdate) => {
    // check if balances changed
    if (!balancesChanged(balanceUpdate, balances.value)) return;
    balances.value = balanceUpdate;
    railUserTokens.value = await Promise.all(balanceUpdate.map((t) => (lookupToken(
      { address: getAddress(bigInt2ETHAddress(t.token)), balance: (t.balance).toString() }
    ))));
    await exportState();
  };

  const importState = async () => {
    const savedState = await localforage.getItem(getStorageKey());
    if (savedState) {
      railgun.importState(savedState, onBalanceUpdate);
    }
  };

  const clear = async () => {
    railgun = null;
    address.value = null;
    loaded.value = false;
    await localforage.setItem(getStorageKey(), null);
    window.location.reload();
  };

  const synchronize = async () => {
    synchronizing.value = true; // sync in progress
    await importState();

    sync()
      .then(() => {
        synchronizing.value = false; // sync done
        loaded.value = true; // set loaded in case there was no state to import
        console.log('railgun loaded & synced');
        return exportState();
      })
      .catch((e) => {
        console.error('error synchronizing; clearing state to retry', e);
        clear();
      });
  };

  const setup = async (provider, mnemonic, contractAddress, deploymentBlock) => {
    // create instance of Railgun if it does not exist
    if (!(railgun instanceof Railgun)) {
      railgun = new Railgun(
        provider, mnemonic, contractAddress, deploymentBlock, onBalanceUpdate,
      );

      address.value = railgun.wallet.address;
      // bring state up to date
      synchronize();
    }
  };

  const getRailgun = () => railgun;

  const setSmallArtifacts = (artifacts) => {
    railgun.setSmallArtifacts(artifacts);
    smallArtifactsSet.value = true;
  };

  function stripToken(token) {
    return { address: token.address, symbol: token.symbol, decimals: token.decimals };
  }
  /**
   * @todo either refactor these methods to accept token so allowance
   * can be updated or listen to events
   */
  const deposit = async (amount, token) => {
    const tokenBalance = railgun.balance.balanceOf(token.address);
    const tokenAddress = token.address;
    const t = stripToken(token);
    const parsedAmount = formatUnits(amount, token.decimals);
    const detail = {
      fromAddress: store.address.value, toAddress: address.value, amount: parsedAmount, token: t,
    };
    if (tokenBalance > 0n) {
      // if user has already deposited token, use depositMerge
      console.log('depositMerge', { amount, tokenAddress });
      return await txHistory.submitTx('depositMerge', railgun.depositMerge(amount, tokenAddress), detail);
    }
    // user does not have token to merge. simple deposit
    console.log('deposit', { amount, tokenAddress });
    return await txHistory.submitTx('deposit', railgun.deposit(amount, tokenAddress), detail);

  };

  /**
   * @param {String} toAddress - ethereum address to deposit to
   * @param {String} amount - string of bigint amount to withdraw
   * @param {String} token.address - token contract address
   */
  const depositTo = async (toAddress, amount, token) => {
    const parsedAmount = formatUnits(amount, token.decimals);
    const tokenAddress = token.address;
    const t = stripToken(token);
    console.log('depositTo', { toAddress, amount, tokenAddress });
    const detail = { fromAddress: store.address.value, toAddress, amount: parsedAmount, token: t };
    return txHistory.submitTx('depositTo', railgun.depositTo(toAddress, amount, token.address), detail);
  };

  /**
   * @param {String} amount - string of bigint amount to withdraw
   * @param {String} token.address - token contract address
   * @param {String} toAddress - ethereum address to withdraw to
   */
  const withdraw = async (amount, token, toAddress) => {
    const parsedAmount = formatUnits(amount, token.decimals);
    const tokenAddress = token.address;
    const t = stripToken(token);
    console.log('withdraw', { amount, tokenAddress, toAddress });
    const detail = { fromAddress: address.value, toAddress, amount: parsedAmount, token: t };
    return await txHistory.submitTx('withdraw', railgun.withdraw(amount, tokenAddress, toAddress), detail);
  };

  const transfer = async (amount, token, toAddress) => {
    const parsedAmount = formatUnits(amount, token.decimals);
    const tokenAddress = token.address;
    const t = stripToken(token);
    console.log('balance from before tx', railgun.balance.balanceOf(token.address));
    // transfer uses Recipient object as first arg
    // expects amount to be BigInt rather than string
    const detail = { fromAddress: address.value, toAddress, amount: parsedAmount, token: t };
    const transferTo = { address: toAddress, amount };
    return await txHistory.submitTx('transfer', railgun.transfer(transferTo, tokenAddress), detail);
  };

  // computed function to signal the user has balance in railgun
  const hasDeposited = computed(() => (railUserTokens.value.length > 0));

  return {
    RP_FEE,
    loaded,
    ready,
    address,
    smallArtifactsSet,
    setup,
    synchronize,
    railUserTokens,
    getRailgun,
    sync,
    // exportState,
    clear,
    setSmallArtifacts,
    deposit,
    depositTo,
    withdraw,
    transfer,
    balance,
    isValidRailAddress,
    isETHAddress,
    hasDeposited,
  };
}
