export default {
  '0x53a': [
    {
      chainId: 1,
      address: '0xCfEB869F69431e42cdB54A4F4f105C19C080A601',
      name: 'testERC20',
      symbol: 'testERC20',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744',
    },
  ],
  '0x539': [
    {
      chainId: 1,
      address: '0xCfEB869F69431e42cdB54A4F4f105C19C080A601',
      name: 'testERC20',
      symbol: 'testERC20',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744',
    },
    {
      chainId: 1,
      address: '0xe76c6c83af64e4c60245d8c7de953df673a7a33d',
      name: 'Railgun',
      symbol: 'RAIL',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/16840/thumb/railgun.jpeg?1625322775',
    },
  ],
  '0x3': [
    {
      chainId: 3,
      address: '0xAa753fb4e77ea8Adb16200865839ffB1d86BAE5E',
      name: 'testERC20',
      symbol: 'testERC20',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/16840/thumb/railgun.jpeg?1625322775',
    },
    {
      chainId: 3,
      address: '0x784Dbb737703225A6d5dEffFc7B395d59E348e3D',
      name: 'RAILGUN',
      symbol: 'RAIL',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/16840/thumb/railgun.jpeg?1625322775',
    },
    {
      chainId: 3,
      address: '0xFab46E002BbF0b4509813474841E0716E6730136',
      name: 'FAU',
      symbol: 'FAU',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/16840/thumb/railgun.jpeg?1625322775',
    },
  ],
  '0x1': [
    {
      chainId: 1,
      address: '0xe76c6c83af64e4c60245d8c7de953df673a7a33d',
      name: 'Railgun',
      symbol: 'RAIL',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/16840/thumb/railgun.jpeg?1625322775',
    },
    {
      chainId: 1,
      address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      name: 'Tether',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707',
    },
    {
      chainId: 1,
      address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
      name: 'Polygon',
      symbol: 'MATIC',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912',
    },
    {
      chainId: 1,
      address: '0x514910771af9ca656af840dff83e8264ecf986ca',
      name: 'Chainlink',
      symbol: 'LINK',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700',
    },
    {
      chainId: 1,
      address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
      name: 'Aave',
      symbol: 'AAVE',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110',
    },
    {
      chainId: 1,
      address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
      name: 'Sushi',
      symbol: 'SUSHI',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688',
    },
    {
      chainId: 1,
      address: '0x6b175474e89094c44da98b954eedeac495271d0f',
      name: 'Dai',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/9956/thumb/dai-multi-collateral-mcd.png?1574218774',
    },
    {
      chainId: 1,
      address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
      name: 'Shiba Inu',
      symbol: 'SHIB',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/11939/thumb/shiba.png?1622619446',
    },
    {
      chainId: 1,
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389',
    },
    {
      chainId: 1,
      address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
      name: 'Uniswap',
      symbol: 'UNI',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604',
    },
    {
      chainId: 1,
      address: '0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2',
      name: 'renZEC',
      symbol: 'RENZEC',
      decimals: 8,
      logoURI: 'https://assets.coingecko.com/coins/images/11564/thumb/renZEC.png?1591186101',
    },
    {
      chainId: 1,
      address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      name: 'WETH',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1547036627',
    },
    {
      chainId: 1,
      address: '0x3832d2f059e55934220881f831be501d180671a7',
      name: 'renDOGE',
      symbol: 'RENDOGE',
      decimals: 8,
      logoURI: 'https://assets.coingecko.com/coins/images/13796/thumb/renDOGE.png?1611897869',
    },
    {
      chainId: 1,
      address: '0x2730d6fdc86c95a74253beffaa8306b40fedecbb',
      name: 'UNICORN Token',
      symbol: 'UNI',
      decimals: 8,
      logoURI: 'https://assets.coingecko.com/coins/images/9383/thumb/54231438.png?1566777314',
    },
    {
      chainId: 1,
      address: '0xfa5047c9c78b8877af97bdcb85db743fd7313d4a',
      name: 'KeeperDAO',
      symbol: 'ROOK',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/13005/thumb/keeper_dao_logo.jpg?1604316506',
    },
    {
      chainId: 1,
      address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      name: 'Wrapped Bitcoin',
      symbol: 'WBTC',
      decimals: 8,
      logoURI: 'https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744',
    },
    {
      chainId: 1,
      address: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
      name: 'renBTC',
      symbol: 'RENBTC',
      decimals: 8,
      logoURI: 'https://assets.coingecko.com/coins/images/11370/thumb/renBTC.png?1589985711',
    },
    {
      chainId: 1,
      address: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
      name: 'yearn finance',
      symbol: 'YFI',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/11849/thumb/yfi-192x192.png?1598325330',
    },
  ],
};
