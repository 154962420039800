import { createApp } from 'vue';
// import { /* dom, */ library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Notifications, { notify } from '@kyvg/vue3-notification';
import { Vue3Mq } from "vue3-mq";
import localforage from 'localforage';

import App from './App.vue';
import router from './router';

// dom.watch();

const { NODE_ENV } = process.env;
const APP_NAME = 'RAILGUN Privacy';
localforage.config({ name: APP_NAME });
App.name = APP_NAME;

// library.add(faSpinner, faAngleDown)

const app = createApp(App)
  // .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(Notifications)
  // Uses bootstrap 5 breakpoints by default: https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
  .use(Vue3Mq);

// add pop-ups for uncaught errors and warnings
app.config.errorHandler = (err) => {
  console.error(err);
  notify({ title: 'Error', text: err?.message || err, type: 'error', duration: 15000 });
};

// Custom warning handler (only runs in development)
app.config.warnHandler = (warn) => {
  console.warn(`[WARNING] ${warn}`);
  notify({ title: 'Warning', text: warn, type: 'warn', duration: 15000 });
};

// enable performance counters if dev mode (run serve)
app.config.performance = (NODE_ENV === 'development');

app.mount('#app');
