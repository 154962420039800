import { Web3Provider } from '@ethersproject/providers';
import { getAddress } from '@ethersproject/address';
import { BigNumber } from '@ethersproject/bignumber';
import { ref } from 'vue';
import useMetamask from './useMetamask';
import useWalletconnect from './useWalletconnect';

function chainInit(provider) {
  return new Web3Provider(provider);
}

// TODO non-reactive due to issue with proxying ethers stuff
const state = {
  provider: null,
  rawProvider: null,
  signer: null,
  chainConfig: null,
};

const chainId = ref(null);
const loadingAccount = ref(false);
const walletConnected = ref(false);
const wrongNetwork = ref(false);
const walletSource = ref(null);
const connectError = ref(null);
const walletLocked = ref(true);

const connectCallback = ref(null);
const disconnectCallback = ref(null);
const chainChangedCallback = ref(null);
const accountsChangedCallback = ref(null);
const setupProviderCallback = ref(null);

// Callback helpers
const onConnect = (callback) => {
  connectCallback.value = callback;
};

const onDisconnect = (callback) => {
  disconnectCallback.value = callback;
};

const onChainChanged = (callback) => {
  chainChangedCallback.value = callback;
};

const onAccountsChanged = (callback) => {
  accountsChangedCallback.value = callback;
};

const onSetupProvider = (callback) => {
  setupProviderCallback.value = callback;
};

export default function useChain(config) {
  if (state.chainConfig === null) {
    state.chainConfig = config;
  }

  const chainIds = Object.keys(config).map((key) => (Number(key).toString()));

  const verifyProvider = () => {
    if (!state.provider) {
      throw new Error('errors.not_connected');
    }
  };

  const useWallet = (walletName) => {
    switch (walletName) {
      case 'metamask':
        walletSource.value = useMetamask(config);
        break;
      case 'walletconnect':
        walletSource.value = useWalletconnect(config);
        break;
      default:
        connectError.value = 'Invalid wallet provider';
    }
  };

  const setupWallet = async (walletName) => {
    connectError.value = null;
    useWallet(walletName);
    return walletSource.value.getProvider();
  };

  const connect = async (address) => {
    if (getAddress(address) !== await state.signer.getAddress()) {
      const signerAddress = await state.signer.getAddress();
      console.log(`address ${getAddress(address)} !== signerAddress ${signerAddress}`);
      throw new Error('failed address equality assertion');
    }
    const network = await state.provider.getNetwork();
    if (connectCallback.value) {
      await connectCallback.value(getAddress(address), network, false);
    }
    walletConnected.value = true;
  };

  const disconnect = () => {
    state.provider = null;
    state.rawProvider = null;
    walletConnected.value = false;
    wrongNetwork.value = false;
    if (disconnectCallback.value) {
      disconnectCallback.value();
    }
  };

  /**
   * @param {rawProvider} provider
   */
  const subscribeProvider = async (provider) => {
    if (!provider.on) {
      console.log('Provider has no "on" method');
      return;
    }
    provider.on('close', () => disconnect());
    provider.on('accountsChanged', async (accounts) => {
      console.log('accountsChanged', accounts);
      if (accounts.length === 0) disconnect(); // user disconnected account
      if (accountsChangedCallback.value) {
        await accountsChangedCallback.value(accounts);
      }
    });
    provider.on('chainChanged', async (value) => {
      chainId.value = value;
      if (chainChangedCallback.value) {
        await chainChangedCallback.value(value);
      }
    });
  };

  const setupProvider = async (walletProvider) => {
    wrongNetwork.value = false;
    state.provider = chainInit(walletProvider);
    state.rawProvider = walletProvider;
    state.signer = state.provider.getSigner();
    if (setupProviderCallback.value) {
      setupProviderCallback.value(state.signer);
    }
    await subscribeProvider(state.provider.provider);

    const network = await state.provider.getNetwork();
    chainId.value = network.chainId;
    if (chainIds && !chainIds.includes(chainId.value.toString())) {
      wrongNetwork.value = true;
      return false;
    }
    return true;
  };

  const reconnectWallet = async (walletName) => {
    loadingAccount.value = true;
    try {
      const walletProvider = await setupWallet(walletName);
      await walletSource.value.reconnectWallet(walletProvider);
      if (await setupProvider(walletProvider)) {
        await connect(await state.signer.getAddress(), await state.provider.getNetwork(), true);
      }
    } catch (e) {
      console.log("Couldn't reconnect");
      // disconnect();
    } finally {
      loadingAccount.value = false;
    }
  };

  const connectWallet = async (walletName) => {
    loadingAccount.value = true;
    try {
      const walletProvider = await setupWallet(walletName);
      await walletSource.value.connectWallet(walletProvider);
      if (await setupProvider(walletProvider)) {
        await connect(await state.signer.getAddress(), await state.provider.getNetwork(), false);
      }
    } catch (error) {
      connectError.value = error.message;
    } finally {
      loadingAccount.value = false;
    }
  };


  const disconnectWallet = async () => {
    console.log('useChain.disconnectWallet');
    if (state.provider && state.provider.close) { // close deprecated on metamask for .disconnect
      await state.provider.close();
    }
    disconnect();
  };

  const request = (method, params) => {
    verifyProvider();
    return state.provider.provider.request(method, params);
  };

  const getTx = (hash) => {
    verifyProvider();
    return state.provider.getTransaction(hash);
  };

  const getTxReceipt = (hash) => {
    verifyProvider();
    return state.provider.getTransactionReceipt(hash);
  };

  const getError = (exception) => (
    walletSource.value.getError(exception)
  );

  const getBalance = (address) => {
    verifyProvider();
    return state.provider.getBalance(address);
  };

  const toEth = (val) => {
    const wei = BigNumber.from(val.toString());
    return wei.div(BigNumber.from(10n ** 18n)).toNumber();
  };
  const toEthDisplay = (val) => (
    toEth(val).toLocaleString()
  );
  const toWei = (val) => {
    const eth = BigNumber.from(val.toString());
    return eth.mul(BigNumber.from(10n ** 18n)).toString();
  };

  const getSigner = () => state.signer;
  const getProvider = () => state.provider;
  const getRawProvider = () => state.rawProvider;

  return {
    // Callbacks
    onChainChanged,
    onAccountsChanged,
    onSetupProvider,
    onConnect,
    onDisconnect,
    // Reactive variables
    loadingAccount,
    connectError,
    wrongNetwork,
    walletConnected,
    walletLocked,
    // Methods
    connectWallet,
    reconnectWallet,
    disconnectWallet,
    request,
    getTx,
    getTxReceipt,
    getSigner,
    getProvider,
    getRawProvider,
    getError,
    getBalance,
    toEth,
    toEthDisplay,
    toWei,
  };
}
