<template>
  <video autoplay loop muted playsinline>
    <source
      v-if="isExtraLarge"
      type="video/mp4"
      src="https://ipfs.io/ipfs/QmX18bAZM2yCqqV5ZK45uJ1Vkm7um4TfypGwfdAkT6AMbM"
    />
    <source
      v-if="isLarge"
      type="video/mp4"
      src="https://ipfs.io/ipfs/QmT7vk6FPhYBPVgXAfUsHqN4Cdm339nejffEVJMHbJnhyd"
    />
    <source
      v-if="isMedium"
      type="video/mp4"
      src="https://ipfs.io/ipfs/QmNgJZx2YqBa1PJsEM1EUri918MVxCDg4JEf8F9vHwcDVA"
    />
    <source
      v-if="isSmall"
      type="video/mp4"
      src="https://ipfs.io/ipfs/QmTASdoZLu6WCU5xvQ9AXn6Mccg6rvygrAKqa3eHmwFqaK"
    />
  </video>
</template>

<script>
import { computed } from 'vue';
import { useMq } from 'vue3-mq';

export default {
  name: 'VideoLoader',
  setup() {
    const mq = useMq();

    const isExtraLarge = computed(() => mq.current === 'xl' || mq.current === 'xxl');
    const isLarge = computed(() => mq.current === 'lg');
    const isMedium = computed(() => mq.current === 'md' || mq.current === 'sm');
    const isSmall = computed(() => mq.current === 'md' || mq.current === 'sm');

    return {
      isExtraLarge,
      isLarge,
      isMedium,
      isSmall
    };
  }
};
</script>
