<template>
  <nav class="navbar full-width" role="navigation">
    <div class="navbar-brand">
      <div class="navbar-item">
        <router-link to="/">
          <img src="../../public/logo/RAILGUN_logo.svg" alt="" width="150" />
        </router-link>
      </div>
      <div class="navbar-item">
        <span class="tag">{{ chainName }}</span>
      </div>
      <div class="navbar-item">
        <span><router-link to="/multisend">Multisend</router-link></span>
      </div>
    </div>
    <div class="navbar-end">
      <span v-if="connected" class="navbar-item">
        <span class="tag">{{ formattedBalance }} RAIL</span>
      </span>
      <span v-if="connected" class="navbar-item stack">
        <span data-tooltip="My Ethereum Address" class="tag">
          {{ address }}
        </span>
        <span class="tag" data-tooltip="My RAILGUN Address">
          {{ railgunAddress }}
        </span>
      </span>
    </div>
  </nav>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { formatUnits } from '@ethersproject/units';
import ethereum from '@/ethereum';
import { useStore } from '@/store';

export default {
  name: 'Navbar',
  props: {
    connected: Boolean
  },
  setup() {
    const store = useStore();
    const balance = ref('0');
    const formattedBalance = computed(() => formatUnits(balance.value));
    onMounted(async () => {
      balance.value = await ethereum.getRailBalance();
    });
    return {
      formattedBalance,
      address: store.address.value,
      railgunAddress: store.railgunAddress.value,
      chainName: store.chainName.value
    };
  }
};
</script>

<style scoped>
.navbar {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  margin-bottom: 75px;
  background: transparent;
}

.navbar-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-grow: 1;
}

.navbar-item {
  background: transparent;
  align-items: flex-end;
}

/*
.navbar-item img {
  padding-bottom: 0.5rem;
}
*/

.navbar-item.stack {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 1;
}

.tag {
  background: transparent;
}

.full-width {
  width: 100%;
}
</style>
