import { computed, watch, reactive } from 'vue';
import chains from '@/ethereum/chain';

const storeName = 'frontend-store';

const saveState = (value) => {
  console.log('saving state');
  window.localStorage.setItem(storeName, JSON.stringify(value));
};

const initialState = () => ({
  address: null,
  chainId: null,
  walletName: null,
  mnemonic: null,
  railgunAddress: null,
  updated: (new Date()).getTime(),
});

let data = null;
console.log('store');
const raw = window.localStorage.getItem(storeName);
if (raw !== null) {
  data = JSON.parse(raw);
} else {
  data = initialState();
  saveState(data);
}

const state = reactive(data);
const reset = () => {
  data = initialState();
  // saveState(data)
};

watch(state, saveState);

// eslint-disable-next-line import/prefer-default-export
export const useStore = () => ({
  address: computed(() => state.address),
  chainId: computed(() => state.chainId),
  chainName: computed(() => state.chainId && chains[state.chainId].name),
  walletName: computed(() => state.walletName),
  mnemonic: computed(() => state.mnemonic),
  railgunAddress: computed(() => state.railgunAddress),
  chainConfig: computed(() => chains[state.chainId]),
  setConnection: (address, chainId) => {
    state.address = address;
    state.chainId = chainId;
  },
  setAddress: (address) => {
    state.address = address;
  },
  setChainId: (chainId) => {
    state.chainId = chainId;
  },
  setWalletName: (walletName) => {
    state.walletName = walletName;
  },
  setMnemonic: (mnemonic) => {
    state.mnemonic = mnemonic;
  },
  setRailgunAddress: (railgunAddress) => {
    state.railgunAddress = railgunAddress;
  },
  reset,
});
