import { ref, toRaw } from 'vue';
import localforage from 'localforage';
import ethereum from '@/ethereum';

const DEFAULT_URL = 'https://tokens.coingecko.com/uniswap/all.json';

const blacklist = [
  '0xd46ba6d942050d489dbd938a2c909a5d5039a161',
  '0x52132a43D7cAE69B23abE77B226fA1a5BC66b839',
  '0xf911a7ec46a2c6fa49193212fe4a2a9B95851c27',
  '0xe17f017475a709de58e976081eb916081ff4c9d5',
];
function unwrap(arr) {
  return arr.value.map((t) => (toRaw(t)));
}
const tokenList = ref([]);
export default function useTokenList() {

  async function getTokenInfo(address) {
    let tokenInfo;
    tokenInfo = tokenList.value.find(tl => tl.address.toUpperCase() === address.toUpperCase());
    if (tokenInfo) return tokenInfo;
    console.log('not found in tokenList');
    tokenInfo = await ethereum.getTokenInfo(address);
    if (tokenInfo.symbol && tokenInfo.decimals) {
      tokenList.value.push(tokenInfo);
      console.log(`adding ${tokenInfo.symbol} to tokenList`);
      return tokenInfo;
    }
    return {};
  }

  async function lookupToken(token) {
    if (!token.address) throw new TypeError("Token object must contain address");
    if (token.symbol && token.decimals) return token; // already have enough token info

    const tokenInfo = await getTokenInfo(token.address);
    return { ...tokenInfo, ...token };
  }

  const getTokenList = async (customUrl = null) => {
    // if tokenList already set, return
    if (tokenList.value.length) {
      return;
    }
    const cached = await localforage.getItem('tokenList');
    if (cached) {
      tokenList.value = cached;
      return;
    }

    const url = customUrl || DEFAULT_URL;
    const response = await fetch(url, { cache: 'reload' });
    const { tokens } = (await response.json());
    // filter out blacklisted tokens by contract address
    tokenList.value = tokens.filter(
      (token) => !blacklist.includes(token.address),
    );
    localforage.setItem('tokenList', unwrap(tokenList));
  };

  return {
    getTokenList,
    tokenList,
    lookupToken,
  };
}
