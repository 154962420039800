import detectEthereumProvider from '@metamask/detect-provider';

export default function useMetamask() {

  const getProvider = async () => {
    try {
      const provider = await detectEthereumProvider();
      if (provider) {
        return provider;
      }
      throw new Error('errors.no_metamask');
    } catch (_e) {
      throw new Error('errors.no_metamask');
    }
  };

  const getError = (error) => {
    if (error.code === 4001) return 'errors.user_rejected';
    if (error.code === -32603) {
      // Hacky check for nonce issue
      if (error.message.includes('-32000')) {
        return 'errors.nonce_high';
      }
      return 'errors.tx_reverted';
    }
    return 'errors.unknown';
  };

  // eslint-disable-next-line no-unused-vars
  const requestPermissions = async (provider) => provider.request({
    method: 'wallet_requestPermissions',
    params: [{ eth_accounts: {} }],
  });

  const requestAccounts = async (provider) => provider.request({
    method: 'eth_requestAccounts',
    params: [{ eth_accounts: {} }],
  });

  // eslint-disable-next-line no-underscore-dangle
  const isUnlocked = async (provider) => provider._metamask.isUnlocked();

  const ethAccounts = async (provider) => provider.request({
    method: 'eth_accounts',
    params: [],
  });

  const reconnectWallet = async (provider) => ethAccounts(provider);

  const connectWallet = async (provider) => {
    try {
      const accounts = await requestAccounts(provider);
      return accounts;
    } catch (e) {
      throw new Error(getError(e));
    }
  };


  return { getProvider, getError, isUnlocked, connectWallet, reconnectWallet };
};
