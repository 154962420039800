import { reactive, ref, toRaw } from 'vue';
import localforage from 'localforage';
import { useStore } from '@/store';

const store = useStore();
const state = {
  transactions: reactive([]),
  loaded: ref(false),
  executing: ref(false),
};

const TxStatus = {
  SUBMITTED: 'tx.submitted',
  PENDING: 'tx.pending',
  COMPLETED: 'tx.completed',
  REJECTED: 'tx.rejected',
  FAILED: 'tx.failed',
};

// true when busy; show spinner
export default function useTransactionHistory() {

  async function getTxStorageKey() {
    const addr = store.address.value;
    const network = store.chainId.value;
    return `tx_${network}_${addr}`;
  }

  async function storeTransactions() {
    await localforage.setItem(await getTxStorageKey(), toRaw(state.transactions));
  }

  /**
   * read user tokens from storage
   */
  async function loadTxHistory() {
    // if (!state.transactions.length) {
    if (!state.loaded.value) {
      const transactions = await localforage.getItem(await getTxStorageKey()) || [];
      for (const t of transactions) {
        state.transactions.push(t);
      }
      state.loaded.value = true;
    }
  }

  const addTx = async (tx) => {
    state.transactions.unshift(tx);
    await storeTransactions();
  };

  const updateTx = async (txReceipt) => {
    for (let i = 0; i < state.transactions.length; i += 1) {
      const tx = state.transactions[i];
      if (tx.hash === txReceipt.transactionHash) {
        if (txReceipt.status === 1) {
          tx.status = TxStatus.COMPLETED;
        } else {
          tx.status = TxStatus.FAILED;
        }
      }
    }
    await storeTransactions();
  };
  /**
   * borrowed from https://github.com/samatechtw/tpa-dashboard/blob/main/src/chain/useChain.js
   * @param {String} txType - classifier for tx
   * @param {Object} tx - transaction
   */
  const submitTx = async (txType, tx, detail) => {
    let txResult = null;
    state.executing.value = true;
    const txInfo = { hash: null, timestamp: new Date().getTime(), type: txType, detail };
    try {
      txResult = await tx;
      state.executing.value = false;
      await addTx({ ...txInfo, ...{ hash: txResult.hash, status: TxStatus.SUBMITTED } });
    } catch (e) {
      console.log(`error submitting ${txType}`, e);
      const status = { status: e.code === 4001 ? TxStatus.REJECTED : TxStatus.FAILED };
      await addTx({ ...txInfo, ...status });
      throw new Error(e.message);
    } finally {
      state.executing.value = false;
    }
    try {
      console.log('waiting for mining');
      await updateTx(await txResult.wait(1));
      console.log('mining complete');
      console.log(`${txType} - ${txResult.hash}`);
    } catch (e) { // code, reason, receipt, transaction, transactionHash, message
      await updateTx(e.receipt);
      console.log(`error executing ${txType}`, e);
      throw new Error(e.message);
    }
    return txResult;
  };

  return {
    TxStatus,
    transactions: state.transactions,
    txHistoryLoaded: state.loaded,
    executing: state.executing,
    load: loadTxHistory,
    addTx,
    updateTx,
    submitTx,
  };
}
