import { Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';
import chains from '@/ethereum/chain';
import chainTokens from '@/ethereum/chainTokens';
import { useStore } from '@/store';
import { useChain } from '@/vue3-eth';

const store = useStore();
const chain = useChain(chains);

// Cached values
const abis = {};
const contracts = {};

let defaultTokens = null;
const supportedChains = () => (Object.keys(chains));

async function getRawProvider() { return chain.getRawProvider(); }

async function getProvider() { return chain.getProvider(); }

async function getSigner() { return chain.getSigner(); }

async function getChainId() { return store.chainId.value; }

const getAddress = () => (store.address.value);

async function getChainConfig() { return store.chainConfig.value; }

async function getDefaultTokens() {
  if (defaultTokens === null) {
    defaultTokens = chainTokens[await getChainId()];
  }
  return defaultTokens;
}

async function getAbi(name) {
  if (abis[name] === undefined) {
    const { default: abi } = await import(`./abi/${name}.json`);
    abis[name] = abi;
  }
  return abis[name];
}

/**
 * @param {string} name - contract name
 * @returns {Contract|null}
 */
async function getContract(name) {
  if (contracts[name] === undefined) {
    const chainConfig = store.chainConfig.value;
    const address = chainConfig.addresses[name];
    if (address) {
      const abi = await getAbi(name);
      contracts[name] = new Contract(address, abi, await getSigner());
    } else { // address not set on this network
      contracts[name] = null;
    }
  }
  return contracts[name];
}

/**
 * @returns {string} - bignumberish
 */
const getRailBalance = async () => {
  const contract = await getContract('rail');
  if (contract) {
    return (await contract.balanceOf(await getAddress())).toString();
  }
  return '0';
};

async function getErc20Contract(tokenAddress) {
  const abi = await getAbi('erc20');
  return new Contract(tokenAddress, abi, await getSigner());
}

async function getTokenInfo(address) {
  const contract = await getErc20Contract(address);
  const symbol = await contract.symbol();
  const decimals = await contract.decimals();
  const name = await contract.name();
  return { symbol, decimals, name, address };
}

/**
 * @param {String} tokenAddress - address
 * @param {String} owner - wallet address
 * @param {String} spender - contract address
 * @returns {BigNumber}
 */
async function getAllowance(tokenAddress, spender) {
  return (await getErc20Contract(tokenAddress))
    .allowance(await getAddress(), spender);
}

async function approve(tokenAddress, spender, amount) {
  return (await getErc20Contract(tokenAddress))
    .approve(spender, amount);
}

/**
 * @param {Object} token token object containing address
 * @param {String} wallet address
 * @returns {BigNumber}
 */
async function getBalance(token, wallet) {
  let balance = 0;
  try {
    const contract = await getErc20Contract(token.address);
    balance = contract.balanceOf(wallet);
  } catch (e) {
    console.log('error getting balance, returning 0', e);
    balance = 0;
  }
  return balance;
}

async function getBalances(tokens, wallet = null) {
  const promises = [];
  for (const token of tokens) {
    promises.push(getBalance(token, wallet));
  }
  return Promise.allSettled(promises).then((values) => {
    const result = [];
    for (let i = 0; i < promises.length; i += 1) {
      const value = values[i];
      if (value.status === 'fulfilled') {
        result.push(value.value);
      } else { // error fetching balance
        result.push(0);
      }
    }
    return result;
  });
}

async function getChainTime() {
  const localProvider = await getProvider();
  const blockNumber = await localProvider.getBlockNumber();
  const block = await localProvider.getBlock(blockNumber);
  return block.timestamp;
}

function toHuman(stringNumber) {
  return BigNumber.from(stringNumber).div(10n ** 18n).toFixed();
}

function fromHuman(stringNumber) {
  return BigNumber.from(stringNumber).mul(10n ** 18n);
}

const getTx = async (hash) => ((await getProvider()).getTransaction(hash));

const getTxReceipt = async (hash) => ((await getProvider()).getTransactionReceipt(hash));

async function mintTestTokens(amount) {
  const contract = await getContract('testERC20');
  const address = await getAddress();
  return contract.mint(address, amount);
}

export default {
  supportedChains,
  getRawProvider,
  getProvider,
  getSigner,
  getChainId,
  getAddress,
  getRailBalance,
  getChainConfig,
  getDefaultTokens,
  getTokenInfo,
  getContract,
  getChainTime,
  getAllowance,
  approve,
  getBalance,
  getBalances,
  toHuman,
  fromHuman,
  getTx,
  getTxReceipt,
  mintTestTokens,
};
