export default {
  '0x1': {
    name: 'Ethereum',
    blockExplorer: 'https://etherscan.io/tx/',
    deploymentBlock: 12852553,
    addresses: {
      railgunLogic: '0xbf0Af567D60318f66460Ec78b464589E3f9dA48e',
      delegator: '0xB6d513f6222Ee92Fff975E901bd792E2513fB53B',
      rail: '0xe76C6c83af64e4C60245D8C7dE953DF673a7A33D',
      staking: '0xee6a649aa3766bd117e12c161726b693a1b2ee20',
      voting: '0xfc4B580C9bda2EEf4E94D9Fb4bcB1F7a61660cf9',
    },
  },
  '0x3': {
    name: 'Ropsten',
    blockExplorer: 'https://ropsten.etherscan.io/tx/',
    deploymentBlock: 10785971,
    testing: true,
    addresses: {
      railgunLogic: '0x2c29584A3Cda058EcE9d98865F2d37175690090A',
      delegator: '',
      rail: '',
      staking: '',
      voting: '',
      testERC20: '0xAa753fb4e77ea8Adb16200865839ffB1d86BAE5E',
    },
  },
  '0x539': {
    name: 'Ganache',
    blockExplorer: 'https://etherscan.io/tx/',
    deploymentBlock: -1,
    testing: true,
    addresses: {
      railgunLogic: '0x254dffcd3277C0b1660F6d42EFbB754edaBAbC2B',
      delegator: '',
      rail: '0x254dffcd3277C0b1660F6d42EFbB754edaBAbC2B',
      staking: '',
      voting: '',
    },
  },
  '0x53a': {
    name: 'RAILGUN TestNet',
    blockExplorer: 'https://etherscan.io/tx/',
    deploymentBlock: -1,
    testing: true,
    addresses: {
      railgunLogic: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
      delegator: '',
      rail: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
      staking: '',
      voting: '',
    },
  },
  '0x7a69': {
    name: 'Fork',
    deploymentBlock: 12852553,
    blockExplorer: 'https://etherscan.io/tx/',
    testing: false,
    addresses: {
      contract: '0xbf0Af567D60318f66460Ec78b464589E3f9dA48e',
      delegator: '0xB6d513f6222Ee92Fff975E901bd792E2513fB53B',
      rail: '0xe76C6c83af64e4C60245D8C7dE953DF673a7A33D',
      staking: '0xee6a649aa3766bd117e12c161726b693a1b2ee20',
      voting: '0xfc4B580C9bda2EEf4E94D9Fb4bcB1F7a61660cf9',
    },
  },
};
