<template>
  <footer class="footer has-text-centered ">
    <div class="content is-size-6 has-text-weight-light">
      <p>Beta Version: DEX interaction mode available soon™</p>
    </div>
    <div class="content is-size-6 has-text-weight-light">
      <p class="has-text-danger">
        Until relayers are in place, your ethereum address is visible as the payer of gas fees.
        Transactions within RAILGUN do shield token and amount. Initial deposits to RAILGUN are,
        like any ethereum transaction, public information - your address, the token being deposited,
        and the amount are visible.
      </p>
      <p class="has-text-danger">
        DO NOT deposit rebasing or lottery tokens. They will not work.
      </p>
    </div>
    <div class="content is-size-8 has-text-weight-light">
      <p>
        THERE IS NO TECHNICAL SUPPORT &amp; NO WAY FOR ANY OTHER PERSON OTHER THAN YOURSELF TO
        RETURN FUNDS.
      </p>
      <p>
        USE AT YOUR OWN RISK. YOU ACCEPT FULL RESPONSIBILITY FOR YOUR UNDERSTANDING OF THIS
        DECENTRALISED ETHEREUM CODE.
      </p>
      <p>
        THE WEBSITE HOST HAS NO CONTROL OR RESPONSIBILITY OVER THE SMART CONTRACT OR YOUR ACTIONS.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style scoped>
.footer {
  background-color: #000;
}
/* even smaller than is-size-7 */
.is-size-8 {
  font-size: 0.7rem !important;
}
</style>
