import { createRouter, createWebHashHistory } from 'vue-router';
import App from '../App.vue';

const routes = [
  {
    path: '/',
    name: 'split',
    meta: {
      title: 'Home',
    },
    component: () => import(/* webpackChunkName: "Split" */ '../views/Split.vue'),
  },
  {
    path: '/multisend',
    name: 'multisend',
    meta: { title: 'MultiSend' },
    component: () => import(/* webpackChunkName: 'MultiSend' */ '../views/MultiSend.vue'),
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  // eslint-disable-next-line no-useless-concat
  document.title = `${App.name}` + ` | ${to.meta.title}`;
});

export default router;
