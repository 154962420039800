<template>
  <div class="app">
    <div v-if="railgun.ready.value" class="main block">
      <Navbar
        v-if="chain.walletConnected.value && railgun.loaded.value"
        :connected="chain.walletConnected.value"
      />
      <router-view />
    </div>
    <!-- </transition> -->
    <VideoLoader v-if="!railgun.ready.value" />

    <!-- IPFS background downloader -->
    <IpfsLoader />

    <Footer />
    <notifications position="bottom right" />
    <ConnectModal :signing="signing" />
    <div id="version" style="display: none">{{ version }}</div>
  </div>
</template>

<script>
import chainConfig from '@/ethereum/chain';
import { useStore } from '@/store';
import { useChain } from '@/vue3-eth';
import { createMnemonicFromEtherWallet } from 'railgun-privacy.js/railgun-wallet';
import { defineAsyncComponent, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import './styles/style.scss';

import Footer from '@/components/Footer.vue';
import Navbar from '@/components/Navbar.vue';
import VideoLoader from '@/components/VideoLoader.vue';
import useRailgun from '@/composables/useRailgun';

// const Split = defineAsyncComponent(() => import(/* webpackChunkName: "Split" */ '@/views/Split.vue'));
const IpfsLoader = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'IpfsLoader' */ '@/components/IpfsLoader.vue')
);
const ConnectModal = defineAsyncComponent(() =>
  import(/* webpackChunkName: 'ConnectModal' */ '@/components/ConnectModal.vue')
);

export default {
  name: 'App',
  components: {
    Navbar,
    IpfsLoader,
    Footer,
    ConnectModal,
    VideoLoader,
  },
  setup() {
    const railgun = useRailgun(); // railgun privacy wrapper
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const chain = useChain(chainConfig);
    const signing = ref(false); // waiting on user signature

    function reloadWindow(connectAuto) {
      console.log('=== reloadWindow ===');
      router.push({ query: { ...route.query, auto: connectAuto } });
      window.location.reload();
    }
    function reset() {
      console.log('===reset===');
      store.reset();
      railgun.clear();
      reloadWindow(false);
    }

    // useChain calls onConnect when accounts change. this is informational.
    async function accountsChanged(accounts) {
      console.log('accountsChanged', accounts);
      reset();
    }
    chain.onAccountsChanged(accountsChanged);

    async function walletConnected(address, network, isReconnect) {
      console.log(`=== App.walletConnected ${isReconnect ? '(reconnect)' : ''} ===`);
      const chainId = `0x${network.chainId}`;
      // address changed; get mnemonic for it
      if (store.address.value !== address || store.mnemonic.value === null) {
        const signer = chain.getSigner();
        signing.value = true;
        const mnemonic = await createMnemonicFromEtherWallet(signer);
        signing.value = false;
        store.setMnemonic(mnemonic);
      }
      store.setConnection(address, chainId);
      const contractAddress = chainConfig[chainId].addresses.railgunLogic;
      const { deploymentBlock } = chainConfig[chainId];
      await railgun.setup(
        chain.getProvider(),
        store.mnemonic.value,
        contractAddress,
        deploymentBlock
      );
      store.setRailgunAddress(railgun.address.value);
    }
    chain.onConnect(walletConnected);
    chain.onDisconnect(reset);
    chain.onChainChanged(reset);

    onBeforeMount(() => {
      if (store.address.value) {
        chain.reconnectWallet('metamask');
      }
    });

    return {
      signing,
      store,
      railgun,
      chain,
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>

<style scoped>
/*
  .app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  main {
    flex: 1 0 auto;
    min-height: auto;
  }
*/
</style>
